import React from "react";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Logo } from "../resources/images/logo.svg";

const useStyles = makeStyles(() => ({
  image: {
    alignSelf: "center",
    width: "auto",
    height: "auto",
    padding: "20px",
    background: "white",
    color: "red",
    borderRadius: "20%",
  },
}));

const LogoImage = ({ color }) => {
  const classes = useStyles();

  return <Logo className={classes.image} style={{ color }} />;
};

export default LogoImage;
